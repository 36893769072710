import Vue from 'vue';
import Component from 'vue-class-component';
import { Media } from '@/node_modules/@osp/design-system/types/media';
import { useCmsContentStore } from '~/@api/store/cmsContentApi';
import { useRoutingStore } from '~/@api/store/routingApi';
import { useUserStore } from '~/@api/store/userApi';
import { SpaLink, SpaLinkRel } from '~/generated/hybris-raml-api';
import { getDynamicYieldScripts, getTrackingPreconnects } from '~/tracking/trackingutils';
import DefaultHtmlHeadPreconnects from '~/nuxt.config.head.preconnect.js';
import DefaultHtmlHeadLinks from '~/nuxt.config.head.link.js';
import { importRunTask } from '~/app-utils/dynamic-imports';

const sanitizeHeadLinks = (links = []) => {
	return links.filter((link, idx) => {
		if (link.rel === 'canonical') {
			return links.indexOf(links.find((l) => l.rel === 'canonical')) === idx;
		}

		return true;
	});
};

@Component({
	props: {
		footer: { type: Boolean, default: true },
		header: { type: Boolean, default: true },
	},
	head() {
		const { state: routingState } = useRoutingStore(this.$store);

		return {
			htmlAttrs: {
				lang: useUserStore(this.$store).state.user?.language?.isocode || 'de',
			},
			link: [
				...DefaultHtmlHeadPreconnects,
				...getTrackingPreconnects(this.$store),
				...sanitizeHeadLinks(routingState.spaData.links),
				...DefaultHtmlHeadLinks,
			],
			meta: routingState.spaData.meta as any,
			title: routingState.spaData.title,
			script: [...getDynamicYieldScripts(this)],
			__dangerouslyDisableSanitizersByTagID: {
				DY_recommendationContext: ['innerHTML'],
			},
		};
	},
})
export default class DefaultRoute extends Vue {
	get showDYRecommendationsSlot(): boolean {
		return !!useCmsContentStore(this.$store)?.state?.slots?.DYRecommendations;
	}

	get showMiddleContentSlot(): boolean {
		return !!useCmsContentStore(this.$store)?.state?.slots?.MiddleContent;
	}

	get renderMiddleContent() {
		return !!useCmsContentStore(this.$store)?.state?.slots?.MiddleContent;
	}

	get renderMessageboxSlot() {
		return !!useCmsContentStore(this.$store)?.state?.slots?.MessageboxSlot;
	}

	beforeCreate() {
		this.$root.$on('preload', (event) => {
			importRunTask().then((ref) => {
				ref.runTask(() => {
					const preloads = this.getPreloads(event);

					if (preloads.length > 0) {
						this.addPreloadLinks(preloads);
					}
				});
			});
		});
	}

	getPreloads(event) {
		const preloads: SpaLink[] = [];

		if (event.type === 'picture') {
			const image = event.data as Media;
			let lastMinWidth = null;

			// Preload picture sources (the sources must be ordered! smallest source at last)
			image.sources?.forEach((source) => {
				preloads.push({
					rel: SpaLinkRel.preload,
					as: 'image',
					href: source.srcset,
					media: source.media + (lastMinWidth ? ` and (max-width: ${lastMinWidth - 1}px)` : ''),
				});

				const size = parseInt(source.media.replace(/\D/g, ''));

				if (size) {
					lastMinWidth = size;
				}
			});
			// Preload picture image
			preloads.push({
				rel: SpaLinkRel.preload,
				as: 'image',
				href: image.src,
				...(lastMinWidth && {
					media: `(max-width: ${lastMinWidth - 1}px)`,
				}),
			});
		} else if (event.type === 'image') {
			const image = event.data;

			// Preload image sources (deprecated - Scene7ImageLoader)
			preloads.push({
				rel: SpaLinkRel.preload,
				as: 'image',
				href: image.src,
				imagesrcset: image.srcset,
				imagesizes: image.sizes,
			});
		} else {
			// Preload anything else
			preloads.push({ rel: SpaLinkRel.preload, href: event.data });
		}

		return preloads;
	}

	addPreloadLinks(preloads: SpaLink[]): void {
		importRunTask().then(({ runTask }) => {
			runTask(() => useRoutingStore(this.$store).api.addLinks(preloads));
		});
	}
}
